import Constant from '@/const';
import Swal from 'sweetalert2';

const axios = require('axios');

export default {
  namespaced: true,
  state: {
    triaseAssesment: {
      triaseInspect: null,
      triaseLevel: null,
      workingPsychological: null,
    },
    emergencyAssesment: null,
    emergencyDoctor: null,
    emergencyNurse: null,
    tempEmergencyIo: null,
  },
  getters: {
    triaseAssesment: (state) => state.triaseAssesment,
    emergencyAssesment: (state) => state.emergencyAssesment,
    emergencyDoctor: (state) => state.emergencyDoctor,
    emergencyNurse: (state) => state.emergencyNurse,
    tempEmergencyIo: (state) => state.tempEmergencyIo,
  },
  mutations: {
    saveAssessment(state, payload) {
      if (payload.section === '') {
        state[payload.key] = payload.data;
      } else {
        state[payload.key][payload.section] = payload.data;
      }
    },
    tempSaveIo(state, payload) {
      state.tempEmergencyIo = payload.data;
    },
    emergencyDoctor(state, payload) {
      if (payload.status === 'remove') {
        state.emergencyDoctor = null;
      }
      if (payload.status === 'save') {
        state.emergencyDoctor = payload;
      }
    },
    emergencyNurse(state, payload) {
      if (payload.status === 'remove') {
        state.emergencyNurse = null;
      }
      if (payload.status === 'save') {
        state.emergencyNurse = payload;
      }
    },
    removeTempIo(state) {
      state.tempEmergencyIo = null;
    },
    removeAssessment(state) {
      state.triaseAssesment = {
        triaseInspect: null,
        triaseLevel: null,
        workingPsychological: null,
      };
      state.emergencyAssesment = null;
      state.tempEmergencyIo = null;
    },
  },
  actions: {
    registerEmergencyPatient({ rootGetters, dispatch }) {
      return axios
        .post(
          Constant.apiUrl
            .concat('/patient/IGDqueue/register/')
            .concat(rootGetters.userLoggedIn.id),
        )
        .then((response) => {
          const { data } = response.data;
          dispatch('postTriaseNurseAssessment', data)
            .then(() => {
              dispatch('postTriaseDoctorAssessment', data);
            })
            .catch((error) => {
              if (error.response) {
                const message = error.response.data.pesan;
                Swal.fire({
                  type: 'error',
                  title: 'Oops...',
                  text: message,
                });
              } else if (error.request) {
                Swal.fire({
                  type: 'error',
                  title: 'Tidak dapat menyambung ke server',
                  text: 'Silahkan hubungi tim IT',
                });
              } else {
                // eslint-disable-next-line
                console.log('Something happened in the middle of request');
              }
            });
        });
    },
    postTriaseNurseAssessment({ getters, rootGetters }, dataPatient) {
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/4/1/')
          .concat(dataPatient._id)
          .concat('/2'),
        {
          detail_poly: [
            {
              detail_nursery: [
                {
                  triase: {
                    entry:
                      getters.triaseAssesment.triaseInspect.cara_masuk.type,
                    mounted: getters.triaseAssesment.triaseInspect.attached,
                    reason: {
                      type: getters.triaseAssesment.triaseInspect
                        .alasan_kedatangan.type,
                      info: getters.triaseAssesment.triaseInspect
                        .alasan_kedatangan.detail,
                    },
                    vehicle:
                      getters.triaseAssesment.triaseInspect.kendaraan.type,
                    agent:
                      getters.triaseAssesment.triaseInspect.identitas_pengantar,
                    trauma_mechanism: {
                      individual_accident: {
                        status:
                          getters.triaseAssesment.triaseInspect.mekanisme_trauma
                            .condition[0],
                        place:
                          getters.triaseAssesment.triaseInspect.mekanisme_trauma
                            .accident_location[0],
                        date: getters.triaseAssesment.triaseInspect
                          .mekanisme_trauma.date_pick[0],
                        time: getters.triaseAssesment.triaseInspect
                          .mekanisme_trauma.time_pick[0],
                      },
                      accident_between: {
                        status:
                          getters.triaseAssesment.triaseInspect.mekanisme_trauma
                            .condition[1],
                        vehicle1:
                          getters.triaseAssesment.triaseInspect.mekanisme_trauma
                            .point_start,
                        vehicle2:
                          getters.triaseAssesment.triaseInspect.mekanisme_trauma
                            .point_stop,
                        place:
                          getters.triaseAssesment.triaseInspect.mekanisme_trauma
                            .accident_location[1],
                        date: getters.triaseAssesment.triaseInspect
                          .mekanisme_trauma.date_pick[1],
                        time: getters.triaseAssesment.triaseInspect
                          .mekanisme_trauma.time_pick[1],
                      },
                      fall_trauma: {
                        status:
                          getters.triaseAssesment.triaseInspect.mekanisme_trauma
                            .condition[2],
                        info: getters.triaseAssesment.triaseInspect
                          .mekanisme_trauma.fall_from_height,
                      },
                      burns_trauma: {
                        status:
                          getters.triaseAssesment.triaseInspect.mekanisme_trauma
                            .condition[3],
                        info: getters.triaseAssesment.triaseInspect
                          .mekanisme_trauma.burn_damage,
                      },
                      electric_trauma: {
                        status:
                          getters.triaseAssesment.triaseInspect.mekanisme_trauma
                            .condition[4],
                        info: getters.triaseAssesment.triaseInspect
                          .mekanisme_trauma.electricity_trauma,
                      },
                      substance_trauma: {
                        status:
                          getters.triaseAssesment.triaseInspect.mekanisme_trauma
                            .condition[5],
                        info: getters.triaseAssesment.triaseInspect
                          .mekanisme_trauma.other_trauma,
                      },
                      primary_complaint:
                        getters.triaseAssesment.triaseInspect.keluhan_utama
                          .value,
                      arrival_condition:
                        getters.triaseAssesment.triaseInspect.arrivalCondition
                          .type,
                    },
                  },
                  triase_level: {
                    pacs: getters.triaseAssesment.triaseLevel.pacs.type,
                    awareness:
                      getters.triaseAssesment.triaseLevel.kesadaran.type,
                    blood_pressure:
                      getters.triaseAssesment.triaseLevel.dataSub[0],
                    temperature: getters.triaseAssesment.triaseLevel.dataSub[4],
                    pulse: getters.triaseAssesment.triaseLevel.dataSub[1],
                    saturation: getters.triaseAssesment.triaseLevel.dataSub[5],
                    respiratory: getters.triaseAssesment.triaseLevel.dataSub[2],
                    pain: getters.triaseAssesment.triaseLevel.dataSub[6],
                    weight_body: getters.triaseAssesment.triaseLevel.dataSub[3],
                    height_body: getters.triaseAssesment.triaseLevel.dataSub[7],
                  },
                  wpss: {
                    awareness:
                      getters.triaseAssesment.triaseLevel.kesadaran.type,
                    blood_pressure_sistolic:
                      getters.triaseAssesment.triaseLevel.dataSub[0],
                    pulse: getters.triaseAssesment.triaseLevel.dataSub[1],
                    respiratory: getters.triaseAssesment.triaseLevel.dataSub[2],
                    saturation: getters.triaseAssesment.triaseLevel.dataSub[5],
                    total:
                      getters.triaseAssesment.workingPsychological.score
                        .totalScore,
                    note: getters.triaseAssesment.workingPsychological
                      .extraNote,
                    decision:
                      getters.triaseAssesment.workingPsychological.score.detail,
                  },
                  timestamps: {
                    created_by: rootGetters.userLoggedIn.id,
                  },
                },
              ],
            },
          ],
        },
      );
    },
    postTriaseDoctorAssessment({ getters, rootGetters }, dataPatient) {
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/4/1/')
          .concat(dataPatient._id)
          .concat('/1'),
        {
          detail_poly: [
            {
              detail_doctor: [
                {
                  case_type: getters.emergencyAssesment.caseType.type,
                  anamnesis: getters.emergencyAssesment.anamnesis,
                  gcs: {
                    eye: getters.emergencyAssesment.gcsInput.eye,
                    motoric: getters.emergencyAssesment.gcsInput.motor,
                    verbal: getters.emergencyAssesment.gcsInput.verbal,
                  },
                  head: getters.emergencyAssesment.bodyExamination[0],
                  eye: getters.emergencyAssesment.bodyExamination[1],
                  mouth: getters.emergencyAssesment.bodyExamination[2],
                  neck: getters.emergencyAssesment.bodyExamination[3],
                  chest: getters.emergencyAssesment.bodyExamination[4],
                  stomatch: getters.emergencyAssesment.bodyExamination[5],
                  motion: getters.emergencyAssesment.bodyExamination[7],
                  action_plan: getters.emergencyAssesment.action.map(
                    (data, index) => {
                      return {
                        info: data.action,
                        time: getters.emergencyAssesment.time[index],
                      };
                    },
                  ),
                  follow_up: {
                    type: getters.emergencyAssesment.furtherAction.type,
                    detail: getters.emergencyAssesment.furtherAction.detail,
                  },
                  timestamps: {
                    created_by: rootGetters.userLoggedIn.id,
                  },
                },
              ],
            },
          ],
        },
      );
    },
  },
};
