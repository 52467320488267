import Constant from '@/const';

const axios = require('axios');
const moment = require('moment');

function checkStringBlank(myString) {
  return /^\s*$/.test(myString);
}

export default {
  namespaced: true,
  state: {
    isFirstTime: false,
    talkTherapyAssesment: {
      anamnesisFill: null,
      supportData: null,
      physicalCheckup: null,
      generalCheck: null,
      spesificCheck: null,
      medicalIntervention: null,
    },
  },
  getters: {
    talkTherapyAssesment: state => state.talkTherapyAssesment,
  },
  mutations: {
    changeFirstTime(state, payload) {
      state.isFirstTime = payload;
    },
    saveAssessment(state, payload) {
      if (payload.section === '') {
        state[payload.key] = payload.data;
      } else {
        state[payload.key][payload.section] = payload.data;
      }
    },
    removeAssessment(state) {
      state.talkTherapyAssesment = {
        anamnesisFill: null,
        supportData: null,
        physicalCheckup: null,
        generalCheck: null,
        spesificCheck: null,
        medicalIntervention: null,
      };
    },
  },
  actions: {
    postIntervention({ getters, rootGetters }) {
      const postPayload = {
        detail_poly: [
          {
            intervensi_detail: [
              {
                intervensi: getters.talkTherapyAssesment.medicalIntervention.todayIntervention.join(
                  ', ',
                ),
                follow_up: getters.talkTherapyAssesment.medicalIntervention.follow_up.filter(
                  arr => arr && arr.id,
                ),
                area:
                  getters.talkTherapyAssesment.medicalIntervention.todayPlace,
                evaluation:
                  getters.talkTherapyAssesment.medicalIntervention.evaluation,
                timestamps: {
                  created_by: rootGetters.userLoggedIn.id,
                },
              },
            ],
          },
        ],
      };
      if (rootGetters.userLoggedIn.role.primary.toLowerCase() === 'casemix') {
        postPayload.detail_poly[0].intervensi_detail[0] = {
          ...postPayload.detail_poly[0].intervensi_detail[0],
          timestamps: {
            created_by:
              getters.talkTherapyAssesment.medicalIntervention.created_by,
            corrected_by: rootGetters.userLoggedIn.id,
            corrected_at: moment().format(),
          },
        };
      }
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/8/2/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/5'),
        postPayload,
      );
    },
    postTalkTherapy({ getters, rootGetters }) {
      let visitorFill;
      if (getters.talkTherapyAssesment.supportData !== null) {
        visitorFill = {
          radiologi: getters.talkTherapyAssesment.supportData.radiology,
          labotary: getters.talkTherapyAssesment.supportData.labotary,
          emg: getters.talkTherapyAssesment.supportData.emg,
          iq: getters.talkTherapyAssesment.supportData.iq,
          bera: getters.talkTherapyAssesment.supportData.bera,
        };
      } else {
        visitorFill = {
          radiologi: '',
          labotary: '',
          emg: '',
          iq: '',
          bera: '',
        };
      }
      const postPayload = {
        isRefference: rootGetters.selectedPatient.isMedicalSupport || false,
        detail_poly: [
          {
            detail_nursery: [
              {
                anamnesis_type:
                  getters.talkTherapyAssesment.anamnesisFill.anamnesis,
                complaint:
                  getters.talkTherapyAssesment.anamnesisFill.primaryComplaint,
                history_of_disease_now: checkStringBlank(
                  getters.talkTherapyAssesment.anamnesisFill.currentSickness[
                    getters.talkTherapyAssesment.anamnesisFill.currentSickness
                      .length - 1
                  ],
                )
                  ? getters.talkTherapyAssesment.anamnesisFill.currentSickness.slice(
                      0,
                      getters.talkTherapyAssesment.anamnesisFill.currentSickness
                        .length - 1,
                    )
                  : getters.talkTherapyAssesment.anamnesisFill.currentSickness,
                history_of_disease_last: checkStringBlank(
                  getters.talkTherapyAssesment.anamnesisFill.historySickness[
                    getters.talkTherapyAssesment.anamnesisFill.historySickness
                      .length - 1
                  ],
                )
                  ? getters.talkTherapyAssesment.anamnesisFill.historySickness.slice(
                      0,
                      getters.talkTherapyAssesment.anamnesisFill.historySickness
                        .length - 1,
                    )
                  : getters.talkTherapyAssesment.anamnesisFill.historySickness,
                visitor: visitorFill,
                physical_checkup: {
                  td: [
                    getters.talkTherapyAssesment.physicalCheckup.td[0],
                    getters.talkTherapyAssesment.physicalCheckup.td[1],
                  ],
                  hr: getters.talkTherapyAssesment.physicalCheckup.hr,
                  temp:
                    getters.talkTherapyAssesment.physicalCheckup.temperature,
                  rr: getters.talkTherapyAssesment.physicalCheckup.rr,
                  height: getters.talkTherapyAssesment.physicalCheckup.height,
                  weight: getters.talkTherapyAssesment.physicalCheckup.weight,
                },
                general_checkup: {
                  pre_natal: getters.talkTherapyAssesment.generalCheck.preNatal,
                  post_natal:
                    getters.talkTherapyAssesment.generalCheck.postNatal,
                  palpation: getters.talkTherapyAssesment.generalCheck.palpasi,
                  motoric:
                    getters.talkTherapyAssesment.generalCheck.motoricAbility,
                },
                special_checkup: {
                  language: getters.talkTherapyAssesment.spesificCheck.language,
                  eat: getters.talkTherapyAssesment.spesificCheck.eatAndSwallow,
                  articulation:
                    getters.talkTherapyAssesment.spesificCheck.articulation,
                  listen: getters.talkTherapyAssesment.spesificCheck.hearing,
                  talk: getters.talkTherapyAssesment.spesificCheck.sound,
                  rhytm:
                    getters.talkTherapyAssesment.spesificCheck.soundOfSmooth,
                },
                diagnostic:
                  getters.talkTherapyAssesment.spesificCheck
                    .talkTherapyDiagnoses,
                plan: checkStringBlank(
                  getters.talkTherapyAssesment.spesificCheck.talkTherapyPlans[
                    getters.talkTherapyAssesment.spesificCheck.talkTherapyPlans
                      .length - 1
                  ],
                )
                  ? getters.talkTherapyAssesment.spesificCheck.talkTherapyPlans.slice(
                      0,
                      getters.talkTherapyAssesment.spesificCheck
                        .talkTherapyPlans.length - 1,
                    )
                  : getters.talkTherapyAssesment.spesificCheck.talkTherapyPlans,
                tool: getters.talkTherapyAssesment.spesificCheck.usedTools,
                timestamps: {
                  created_by: rootGetters.userLoggedIn.id,
                },
              },
            ],
          },
        ],
      };
      if (rootGetters.userLoggedIn.role.primary.toLowerCase() === 'casemix') {
        postPayload.detail_poly[0].detail_nursery[0] = {
          ...postPayload.detail_poly[0].detail_nursery[0],
          timestamps: {
            created_by: getters.talkTherapyAssesment.anamnesisFill.created_by,
            corrected_by: rootGetters.userLoggedIn.id,
            corrected_at: moment().format(),
          },
        };
      }
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/8/1/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/5'),
        postPayload,
      );
    },
  },
};
