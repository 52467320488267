import Constant from '@/const';
import moment from 'moment';

const _ = require('lodash');
const axios = require('axios');

function checkStringBlank(myString) {
  return /^\s*$/.test(myString);
}

export default {
  namespaced: true,
  state: {
    isAdvancedAssesment: false,
    nurseAssesment: {
      fallRiskAssesment: null,
      objectiveData: null,
      subjectiveData: null,
      prayingAbility: null,
      nutritionScreening: null,
      painAssesment: null,
      functionalStatus: null,
    },
    doctorAssessment: null,
    drugHistory: null,
  },
  getters: {
    isAdvancedAssesment: (state) => state.isAdvancedAssesment,
    nurseAssesment: (state) => state.nurseAssesment,
    doctorAssessment: (state) => state.doctorAssessment,
  },
  mutations: {
    changeAssesment(state, payload) {
      state.isAdvancedAssesment = payload;
    },
    saveAssesment(state, payload) {
      if (payload.section === '') {
        state[payload.key] = payload.data;
      } else {
        state[payload.key][payload.section] = payload.data;
      }
    },
    removeAssessment(state) {
      state.nurseAssesment = {
        fallRiskAssesment: null,
        objectiveData: null,
        subjectiveData: null,
        prayingAbility: null,
        nutritionScreening: null,
        painAssesment: null,
        functionalStatus: null,
      };
      state.doctorAssessment = null;
    },
    removeDrug(state) {
      state.drugHistory = null;
    },
  },
  actions: {
    postNurseAssessment({ getters, rootGetters }, payload) {
      let nutritionScreening;
      if (getters.isAdvancedAssesment === false) {
        nutritionScreening = {
          no1: _.get(
            getters,
            'nurseAssesment.nutritionScreening.input.weightDrop',
          ),
          no2: _.get(
            getters,
            'nurseAssesment.nutritionScreening.input.foodIntakeDrop',
          ),
        };
      } else {
        nutritionScreening = {
          no1: 0,
          no2: 0,
        };
      }
      const nurseplan = [];
      getters.nurseAssesment.prayingAbility.diagnoses.forEach((diagnose) => {
        diagnose.plans.forEach((plan) => {
          if (plan.id_plan) nurseplan.push(plan.id_plan);
        });
      });
      const postPayload = {
        detail_poly: [
          {
            flag_assesment: getters.isAdvancedAssesment,
            detail_nursery: [
              {
                subjective: {
                  primary_complaint:
                    getters.nurseAssesment.subjectiveData.description,
                  deasease_history: checkStringBlank(
                    getters.nurseAssesment.subjectiveData.sickness[
                      getters.nurseAssesment.subjectiveData.sickness.length - 1
                    ],
                  )
                    ? getters.nurseAssesment.subjectiveData.sickness.slice(
                        0,
                        getters.nurseAssesment.subjectiveData.sickness.length -
                          1,
                      )
                    : getters.nurseAssesment.subjectiveData.sickness,
                  type_of_disease:
                    getters.nurseAssesment.subjectiveData.typeOfDisease,
                  /*
                    family_sickness: checkStringBlank(getters.nurseAssesment.subjectiveData.familySickness[getters.nurseAssesment.subjectiveData.familySickness.length - 1])
                      ? getters.nurseAssesment.subjectiveData.familySickness.slice(0, getters.nurseAssesment.subjectiveData.familySickness.length-1)
                      : getters.nurseAssesment.subjectiveData.familySickness,
                    */
                  alergic_history: {
                    type:
                      getters.nurseAssesment.subjectiveData.allergyHistory === 0
                        ? 1
                        : 0,
                    detail:
                      // eslint-disable-next-line no-nested-ternary
                      getters.nurseAssesment.subjectiveData.allergyHistory === 0
                        ? []
                        : checkStringBlank(
                            getters.nurseAssesment.subjectiveData.allergies[
                              getters.nurseAssesment.subjectiveData.allergies
                                .length - 1
                            ],
                          )
                        ? getters.nurseAssesment.subjectiveData.allergies
                            .slice(
                              0,
                              getters.nurseAssesment.subjectiveData.allergies
                                .length - 1,
                            )
                            .map((data) => {
                              return {
                                type: data,
                              };
                            })
                        : getters.nurseAssesment.subjectiveData.allergies.map(
                            (data) => {
                              return {
                                type: data,
                              };
                            },
                          ),
                  },
                  psychospiritual_history: {
                    type: getters.nurseAssesment.subjectiveData
                      .psychospiritualHistory.type,
                    detail:
                      getters.nurseAssesment.subjectiveData
                        .psychospiritualHistory.type === 0
                        ? []
                        : getters.nurseAssesment.subjectiveData.psychospiritualHistory.value.map(
                            (data) => {
                              return {
                                type: data,
                              };
                            },
                          ),
                  },
                  drug_history: {
                    type: getters.nurseAssesment.subjectiveData.drugs.type,
                    detail: checkStringBlank(
                      getters.nurseAssesment.subjectiveData.drugs.detail[
                        getters.nurseAssesment.subjectiveData.drugs.detail
                          .length - 1
                      ],
                    )
                      ? getters.nurseAssesment.subjectiveData.drugs.detail.slice(
                          0,
                          getters.nurseAssesment.subjectiveData.drugs.detail
                            .length - 1,
                        )
                      : getters.nurseAssesment.subjectiveData.drugs.detail,
                    dosage: checkStringBlank(
                      getters.nurseAssesment.subjectiveData.drugs.dose[
                        getters.nurseAssesment.subjectiveData.drugs.dose
                          .length - 1
                      ],
                    )
                      ? getters.nurseAssesment.subjectiveData.drugs.dose.slice(
                          0,
                          getters.nurseAssesment.subjectiveData.drugs.dose
                            .length - 1,
                        )
                      : getters.nurseAssesment.subjectiveData.drugs.dose,
                    timestamps: checkStringBlank(
                      getters.nurseAssesment.subjectiveData.drugs.lastUsage[
                        getters.nurseAssesment.subjectiveData.drugs.lastUsage
                          .length - 1
                      ],
                    )
                      ? getters.nurseAssesment.subjectiveData.drugs.lastUsage.slice(
                          0,
                          getters.nurseAssesment.subjectiveData.drugs.lastUsage
                            .length - 1,
                        )
                      : getters.nurseAssesment.subjectiveData.drugs.lastUsage,
                  },
                },
                objective: {
                  general_condition:
                    getters.nurseAssesment.objectiveData.generalCondition,
                  ttv: {
                    blood_presure: [
                      getters.nurseAssesment.objectiveData.sistol,
                      getters.nurseAssesment.objectiveData.diastol,
                    ],
                    temperature: getters.nurseAssesment.objectiveData.ttv[1],
                    pulse: getters.nurseAssesment.objectiveData.ttv[2],
                    RR: getters.nurseAssesment.objectiveData.ttv[3],
                    saturation: getters.nurseAssesment.objectiveData.ttv[4],
                    height: getters.nurseAssesment.objectiveData.ttv[5],
                    weight: getters.nurseAssesment.objectiveData.ttv[6],
                  },
                  functional_status: {
                    tools_usage: _.get(
                      getters,
                      'nurseAssesment.functionalStatus.input.helperTools',
                    ),
                    disability:
                      _.get(
                        getters,
                        'nurseAssesment.functionalStatus.disabilities',
                        [],
                      )
                        .slice(0, -1)
                        .filter(Boolean).length > 0
                        ? _.get(
                            getters,
                            'nurseAssesment.functionalStatus.disabilities',
                            [],
                          )
                        : null,
                  },
                  risk_assessment_falls: {
                    age_type:
                      rootGetters.selectedPatient.meta.age <= 18 ? 1 : 0,
                    children: {
                      diagnosis: _.get(
                        getters,
                        'nurseAssesment.fallRiskAssesment.children.diagnosis',
                        0,
                      ),
                      cognitiveImpairment: _.get(
                        getters,
                        'nurseAssesment.fallRiskAssesment.children.cognitiveImpairment',
                        0,
                      ),
                      environmentFactor: _.get(
                        getters,
                        'nurseAssesment.fallRiskAssesment.children.environmentFactor',
                        0,
                      ),
                      responseToAnesthesia: _.get(
                        getters,
                        'nurseAssesment.fallRiskAssesment.children.responseToAnesthesia',
                        0,
                      ),
                      drugUsed: _.get(
                        getters,
                        'nurseAssesment.fallRiskAssesment.children.drugUsed',
                        0,
                      ),
                      detail: _.get(
                        getters,
                        'nurseAssesment.fallRiskAssesment.children.detail',
                        null,
                      ),
                    },
                    adult: {
                      isBalanced:
                        _.get(
                          getters,
                          'nurseAssesment.fallRiskAssesment.adult.isBalanced',
                          null,
                        ) === 1,
                      toolsHelper:
                        _.get(
                          getters,
                          'nurseAssesment.fallRiskAssesment.adult.toolsHelper',
                          null,
                        ) === 1,
                    },
                  },
                  pain_assesment: {
                    type:
                      _.get(
                        getters,
                        'nurseAssesment.painAssesment.input.pain',
                        null,
                      ) === 0,
                    detail: {
                      score:
                        _.get(
                          getters,
                          'nurseAssesment.painAssesment.input.pain',
                          null,
                        ) === 1
                          ? 0
                          : _.get(
                              getters,
                              'nurseAssesment.painAssesment.input.intensity.level',
                              null,
                            ),
                      provocation: {
                        data: _.get(
                          getters,
                          'nurseAssesment.painAssesment.input.provocation.value',
                          [],
                        ),
                        detail: _.get(
                          getters,
                          'nurseAssesment.painAssesment.input.provocation.detail',
                          null,
                        ),
                      },
                      quality: {
                        data: _.get(
                          getters,
                          'nurseAssesment.painAssesment.input.quality.value',
                          [],
                        ),
                        detail: _.get(
                          getters,
                          'nurseAssesment.painAssesment.input.quality.detail',
                          null,
                        ),
                      },
                      radiation: _.get(
                        getters,
                        'nurseAssesment.painAssesment.input.radiation',
                        null,
                      ),
                      severity: _.get(
                        getters,
                        'nurseAssesment.painAssesment.input.severity',
                        null,
                      ),
                      time: {
                        type: _.get(
                          getters,
                          'nurseAssesment.painAssesment.input.time',
                        ),
                        duration: _.get(
                          getters,
                          'nurseAssesment.painAssesment.input.timeDuration',
                        ),
                      },
                    },
                  },
                  nutrition_screening: nutritionScreening,
                  praying_ability: {
                    no1: {
                      type: _.get(
                        getters,
                        'nurseAssesment.prayingAbility.obligatoryPrayer.type',
                        null,
                      ),
                      detail: _.get(
                        getters,
                        'nurseAssesment.prayingAbility.obligatoryPrayer.detail',
                        null,
                      ),
                    },
                    no2: _.get(getters, 'nurseAssesment.prayingAbility.wudhu'),
                    no3: _.get(getters, 'nurseAssesment.prayingAbility.sholat'),
                  },
                  psychology: _.get(
                    getters,
                    'nurseAssesment.prayingAbility.psychologist',
                  ),
                  socio_economic: _.get(
                    getters,
                    'nurseAssesment.prayingAbility.socialEconomy',
                  ),
                  // nursery_diagnosis:
                  //   getters.nurseAssesment.prayingAbility.diagnoses.map(
                  //     (item) => {
                  //       let plans = item.plans
                  //         .filter((pl) => pl || pl === 0)
                  //         .map((plan) => (plan.value ? plan.value : plan));
                  //       plans = plans.filter((a, i) => plans.indexOf(a) === i);
                  //       return {
                  //         data: item.detail,
                  //         plan: plans,
                  //         // .slice(0, item.plans.length - 1),
                  //       };
                  //     },
                  //   ),
                  nurseplan,
                },
                timestamps: {
                  created_by: rootGetters.userLoggedIn.id,
                },
              },
            ],
          },
        ],
      };
      if (rootGetters.userLoggedIn.role.primary.toLowerCase() === 'casemix') {
        postPayload.detail_poly[0].detail_nursery[0] = {
          ...postPayload.detail_poly[0].detail_nursery[0],
          timestamps: {
            created_by: getters.nurseAssesment.subjectiveData.created_by,
            corrected_by: rootGetters.userLoggedIn.id,
            corrected_at: moment().format(),
          },
        };
      }
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/')
          .concat(payload.polyId)
          .concat('/1/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/2'),
        postPayload,
      );
    },
    postDoctorAssessment({ getters, rootGetters }, payload) {
      const postPayload = {
        detail_poly: [
          {
            detail_doctor: [
              {
                anamnesis: checkStringBlank(
                  getters.doctorAssessment.anamnesis[
                    getters.doctorAssessment.anamnesis.length - 1
                  ],
                )
                  ? getters.doctorAssessment.anamnesis.slice(
                      0,
                      getters.doctorAssessment.anamnesis.length - 1,
                    )
                  : getters.doctorAssessment.anamnesis,
                ttv: {
                  blood_presure: [
                    getters.doctorAssessment.sistol,
                    getters.doctorAssessment.diastol,
                  ],
                  temperature: getters.doctorAssessment.ttv[1],
                  pulse: getters.doctorAssessment.ttv[2],
                  RR: getters.doctorAssessment.ttv[3],
                  saturation: getters.doctorAssessment.ttv[4],
                  height: getters.doctorAssessment.ttv[5],
                  weight: getters.doctorAssessment.ttv[6],
                },
                physical_checkup: {
                  head: checkStringBlank(
                    getters.doctorAssessment.physicalCheckup[0],
                  )
                    ? 'Normal'
                    : getters.doctorAssessment.physicalCheckup[0],
                  eyes: checkStringBlank(
                    getters.doctorAssessment.physicalCheckup[1],
                  )
                    ? 'Normal'
                    : getters.doctorAssessment.physicalCheckup[1],
                  mouth: checkStringBlank(
                    getters.doctorAssessment.physicalCheckup[2],
                  )
                    ? 'Normal'
                    : getters.doctorAssessment.physicalCheckup[2],
                  neck: checkStringBlank(
                    getters.doctorAssessment.physicalCheckup[3],
                  )
                    ? 'Normal'
                    : getters.doctorAssessment.physicalCheckup[3],
                  chest: checkStringBlank(
                    getters.doctorAssessment.physicalCheckup[4],
                  )
                    ? 'Normal'
                    : getters.doctorAssessment.physicalCheckup[4],
                  abdomen: checkStringBlank(
                    getters.doctorAssessment.physicalCheckup[5],
                  )
                    ? 'Normal'
                    : getters.doctorAssessment.physicalCheckup[5],
                  extremitis: checkStringBlank(
                    getters.doctorAssessment.physicalCheckup[6],
                  )
                    ? 'Normal'
                    : getters.doctorAssessment.physicalCheckup[6],
                  genitalia: checkStringBlank(
                    getters.doctorAssessment.physicalCheckup[7],
                  )
                    ? 'Normal'
                    : getters.doctorAssessment.physicalCheckup[7],
                  pediatri: getters.doctorAssessment.pediatricNutritionalStatus,
                  tooth: {
                    type: getters.doctorAssessment.dentalCheckup.type,
                    detail: getters.doctorAssessment.dentalCheckup.detail,
                  },
                },
                diagnostic: getters.doctorAssessment.diagnosis
                  .filter((ar) => ar.detail || (ar.icd && ar.icd.id))
                  .map((diagnose) => {
                    return diagnose.icd && diagnose.icd.id
                      ? {
                          _id: diagnose.icd.id,
                          value: diagnose.icd.value,
                          text: diagnose.icd.text,
                          detail: diagnose.detail || diagnose.icd.text,
                        }
                      : {
                          detail: diagnose.detail,
                        };
                  }),
                drugHistoryClassification:
                  getters.doctorAssessment.drugHistoryClassification,
                return_status: {
                  type: getters.doctorAssessment.action,
                  // detail: getters.doctorAssessment.referral,
                  detail:
                    getters.doctorAssessment.action === 3
                      ? getters.doctorAssessment.internalReferral
                      : getters.doctorAssessment.referral,
                },
                follow_up: getters.doctorAssessment.follow_up
                  .filter((ar) => ar.detail)
                  .map((procedure) => {
                    return procedure.icd && procedure.icd.id
                      ? {
                          _id: procedure.icd.id,
                          value: procedure.icd.value,
                          text: procedure.icd.text,
                          detail: procedure.detail,
                        }
                      : {
                          detail: procedure.detail,
                        };
                  }),
                action_plan: getters.doctorAssessment.plan,
                instruction: getters.doctorAssessment.instruction,
                timestamps: {
                  created_by: rootGetters.userLoggedIn.id,
                },
              },
            ],
          },
        ],
      };
      if (rootGetters.userLoggedIn.role.primary.toLowerCase() === 'casemix') {
        postPayload.detail_poly[0].detail_doctor[0] = {
          ...postPayload.detail_poly[0].detail_doctor[0],
          timestamps: {
            created_by: getters.doctorAssessment.timestamps.created_by,
            corrected_by: rootGetters.userLoggedIn.id,
            corrected_at: moment().format(),
          },
        };
      }
      console.log(postPayload, rootGetters, payload);
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/')
          .concat(payload.polyId)
          .concat('/1/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/1'),
        postPayload,
      );
    },
  },
};
