import axios from 'axios';
import Constant from '@/const';

export const getMaxDepth = async () => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/configuration/read/max_depth_account'),
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const getBackdateDay = async () => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/configuration/read/finance_journal_backdate'),
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

// const getCoa = async (maxDepth) => {
//   try {
//     const response = await axios.get(
//       Constant.apiUrl.concat(`/accounting/coa?depthFilter=${maxDepth}`),
//     );
//     return response.data.data;
//   } catch (error) {
//     return error;
//   }
// };
// {  , getCoa }
